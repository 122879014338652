import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VFlex,{attrs:{"xs12":""}},[(!_vm.isInView)?_c(VCardTitle,{staticClass:"subheading"},[_vm._v(" "+_vm._s(_vm.$t('carriers.services.create.label'))+" "),_c(VSpacer),_c(VIcon,{on:{"click":_vm.close}},[_vm._v(" $closeItem ")])],1):_vm._e()],1),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"carriers.services."}}),_c(VBtn,{attrs:{"plain":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":_vm.submitAfterEventCycle}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }