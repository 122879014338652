<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :overlay-opacity="0.9"
  >
    <div id="html5-qrcode-input" />
    <template #activator="{ on }">
      <v-btn
        outlined
        v-on="on"
      >
        <v-icon>
          $image
        </v-icon>
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
    import {Html5Qrcode} from 'html5-qrcode';

    export default {
        name: "CameraCodeReader",
        props: {
            icon: {
                type: String,
                default: '$scannerIdle'
            },
            className: {
                type: String,
                default: ''
            }
        },
        data: () => ({

            dialog: false,
            html5QrCode: null,
        }),
        watch: {
            dialog: function () {
                if (this.dialog) {
                    this.$nextTick(() => {
                        this.startScanner();
                    });
                } else {
                    this.stopScanner();
                }
            }
        },
        deactivated: function () {
            this.stopScanner();
        },
        destroyed: function () {
            this.stopScanner();
        },
        methods: {
            startScanner: function () {
                // Check if element exists and scanner isn't already initialized
                const readerElement = document.getElementById('html5-qrcode-input');
                if (readerElement && !this.html5QrCode?.isScanning) {
                    this.html5QrCode = new Html5Qrcode('html5-qrcode-input'); // Pass the ID of the element
                    this.html5QrCode.start(
                        {facingMode: "environment"}, // Request back camera
                        {
                            fps: 10,
                            qrbox: {width: 250, height: 250},
                            rememberLastUsedCamera: true,
                        },
                        this.onScanSuccess,
                        this.onScanFailure
                    )
                        .catch((err) => {
                            window.console.error("Error starting scanner:", err);
                            const message = ({
                                NotAllowedError: 'permission',
                                NotFoundError: 'noCamera',
                            }[err.name]) || 'failedToStart';
                            this.advancedSnack({
                                text: "base.cameraReader.error." + message,
                                params: [err.message || err]
                            });
                            this.stopScanner();
                        });
                }
            },
            stopScanner: function () {
                if (this.html5QrCode && this.html5QrCode.isScanning) {
                    this.html5QrCode.stop()
                        .catch(err => {
                            window.console.error("Error stopping scanner:", err);
                        });
                }
                this.dialog = false;
            },
            onScanSuccess: function (decodedText, decodedResult) {
                window.console.info(`Camera code read: ${decodedText}`, decodedResult);
                this.stopScanner();
                this.$emit('scan-result', decodedText);
            },
            onScanFailure: function (error) {
                // Don't emit error here, as it fires constantly when no code is found
                window.console.info(error);
            },
        },
    };
</script>

<style scoped>

</style>
