import {Roles} from "@/enum/roles";

export default {
    title: "Користувачі",
    link: "Користувачі",

    detail: "Профіль користувача",

    username: "Ім'я користувача",
    email: "E-mail",
    first_name: "Ім'я",
    last_name: "Прізвище",
    password: "Пароль",
    phone: "Телефон",
    active: "Активний",
    roles: "Роль користувача",
    is_storekeeper: "Комірник",
    is_chief: "Завідуючий",
    is_packer: "Пакувальник",
    is_organizer: "Організатор",
    is_eshop: "Eshop",
    has_permission: "має дозвіл",

    role: {
        [Roles.STOREKEEPER]: 'Комірник',
        [Roles.CHIEF]: 'Завідувач складу',
        [Roles.PACKER]: 'Пакувальник',
        [Roles.ORGANIZER]: 'Oрганізатор',
        [Roles.ESHOP]: 'Eshop',
    },

    list: {
        unableToLoad: "Список користувачів було неможливо загрузити. Спробуйте знову пізніше."
    },
    create: {
        title: "Створити користувача",
        label: "Створити нового користувача",
        done: "Користувач '{0}' створений.",
        undo: {
            done: "Створення користувача '{0}' успішно відмінено!",
            failed: "Невдалося відмінити створення користувача!"
        }
    },
    update: {
        titleSimple: "Редагувати користувача",
        title: "Редагувати {0}",
        done: "Користувач '{0}' модифікований",
        password: {
            password: "Новий пароль",
            password_confirmation: "Знову новий пароль",
            passwordsDoNotMatch: "Введені паролі не збігаються",
            done: "Пароль користувача {0} змінений!"
        },
        undo: {
            done: "Зміни '{0}' успішно відмінені!",
            failed: "Невдалося відмінити редагування користувача!"
        }
    },

    chooseOneRole: "Виберіть будь ласка принаймні одну роль користувача!",
    isNotChief: "Створений користувач не має роль завідувача скдаду",
    changePassword: "Зміна паролю",
    cannotChangePasswordOfAnotherChief: "Неможливо змінити пароль іншому завідуючому складу, тільки пакувальнику та комірникові.",
    saveChangesFirst: "По-перше, збережіть видалення ролі лідера.",

    permissions: {
        title: "Доступ до підпорядкованих складів",
        titleSingle: "Доступ до підпорядкованого складу",
        info: "Цей параметр обмежує завдання, які відображаються користувачеві. Якщо користувач виконує роль завідуючого складу, він може вільно встановлювати, якими підскладами він хоче керувати.",
        hasAccess: "Має доступ",
        addAccess: "Дозволити доступ до цього підскладу",
        removeAccess: "Відмінити доступ до цього підскладу"
    },

    settings: {
        status: {
            403: "Ви не маєте доступу до чужого налаштування!",
            404: "Налаштування користувача не знайдено!",
            409: "Це налаштування вжа існує!"
        }
    },

    status: {
        404: "Вибраний користувач неіснує!",
        409: "Цей логін вже використовує інший користувач!"
    }
};
