<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="stocks."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-subheader>{{ $t('stocks.address') }}</v-subheader>
          </v-flex>
          <FormAddress
            :form="form.address"
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :plain="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <UserCreateUpdateComponent
      v-if="showCreateUser"
      id="userForm"
      ref="userForm"
      :is-edit="false"
      creating-chief
      @user-updated="onCreateUserCreated"
    />
  </div>
</template>

<script>
    import {StockAPI} from "@/api/StockAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import FormAddress from "@/app/components/form/FormAddress.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {StockForm, StockRender} from "@/app/stocks/definitions/stock.form";
    import {tabTitle} from "@/utils/string";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import UserCreateUpdateComponent from "@/app/users/components/UserCreateUpdate.component";
    import {scrollTo} from "@/service/Vuetify";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "StockCreateUpdate",
        components: {UserCreateUpdateComponent, FormAddress, FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, EventsListenerMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            form: new StockForm,
            formRender: new StockRender,
            valid: true,
            loading: false,
            showCreateUser: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('stocks.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            events: function () {
                return {
                    'create-user': this.onCreateUser,
                    'create-user-cancelled': () => this.showCreateUser = false
                };
            }
        },
        methods: {
            formFetchItem: function () {
                return StockAPI.get(this.stockId);
            },
            onCreateUser: function () {
                this.showCreateUser = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.chief_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('userForm');
                });
            },
            onCreateUserCreated: function (userId) {
                this.showCreateUser = false;
                this.$set(this.formRender.chief_id, 'loading', true);
                this.formRender.chief_id.autocomplete.callFn()
                    .then(response => {
                        const userIdInt = Number.parseInt(userId, 10);
                        const createdUser = response.data.find(user => user.id === userIdInt);
                        this.formRender.chief_id.autocomplete.items = this.formRender.chief_id.autocomplete.thenFn(response);
                        if (createdUser) {
                            this.form.chief_id = userIdInt;
                        } else {
                            this.snack('users.isNotChief');
                        }
                    }).finally(() => this.$set(this.formRender.chief_id, 'loading', false));
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const id = this.stockId;
                    StockAPI.update(id, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'stocks.update.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/stocks/' + id);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    StockAPI.create(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'stocks.create.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push(response.headers.location);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
