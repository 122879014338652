<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="valid"
      @submit.prevent
    >
      <v-flex
        xs12
      >
        <v-layout
          v-for="(currentItem, index) of items"
          :key="index"
          wrap
          align-center
        >
          <v-flex
            xs12
            sm6
            :sm5="lockType === StockLocationLockType.INSTANCE"
            class="px-1"
          >
            <v-autocomplete
              v-model="currentItem.itemId"
              :items="possibleItems"
              :error-messages="currentItem.errors"
              :label="lockItemLabel"
              :loading="loading"
              :prepend-icon="StockLocationLockIcons[lockType === StockLocationLockType.INSTANCE ? StockLocationLockType.PRODUCT : lockType]"
              :rules="index === 0 ? [formRules.required] : []"
              single-line
              :suffix="index === 0 ? '*' : ''"
              @input="itemChanged(index)"
            />
          </v-flex>
          <v-flex
            v-if="lockType === StockLocationLockType.INSTANCE"
            xs12
            sm6
            :sm5="lockType === StockLocationLockType.INSTANCE"
            class="px-1"
          >
            <v-autocomplete
              v-model="currentItem.instanceId"
              :items="has(instances, currentItem.itemId) ? instances[currentItem.itemId].items : []"
              :error-messages="currentItem.instanceErrors"
              :label="$t('stocks.locations.locks.lockItems.' + StockLocationLockType.INSTANCE)"
              :loading="currentItem.itemId && has(instances, currentItem.itemId) && instances[currentItem.itemId].loading"
              :prepend-icon="StockLocationLockIcons[StockLocationLockType.INSTANCE]"
              :rules="index === 0 ? [formRules.required] : []"
              single-line
              :suffix="index === 0 ? '*' : ''"
              @input="instanceChanged(index)"
            />
          </v-flex>
          <v-flex
            xs2
          >
            <v-tooltip
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  v-show="currentItem.itemId !== null"
                  :disabled="loading"
                  icon
                  v-on="on"
                  @click="removeItem(index)"
                >
                  <v-icon>
                    $removeItem
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{ $t('stocks.locations.locks.removeLock') }}
              </span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
      >
        <v-divider
          class="my-3"
        />
      </v-flex>
      <FormFields
        ref="fields"
        :form="form"
        :render="formRender"
        lang-path="stocks.locations.locks."
      />
      <v-flex
        xs12
      >
        <v-divider
          class="my-3"
        />
      </v-flex>
      <v-btn
        :plain="!valid"
        :loading="loading"
        type="submit"
        color="accent"
        class="ma-2"
        @click="submit()"
      >
        <v-icon
          class="mr-2"
        >
          $saveItem
        </v-icon>
        {{ $t('base.save') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
    import {StockLocationLockType, StockLocationLockIcons} from "@/enum/stock_location_lock_type";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import {ProductTypeAPI} from "@/api/ProductTypeAPI";
    import {ProductAPI} from "@/api/ProductAPI";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {StockLocationLockForm, StockLocationLockRender} from "@/app/stocks/locations/locks/definitions/stockLocationLock.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {has} from "@/utils/object";
    import {StockAPI} from "@/api/StockAPI";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import formRules from "@/utils/formRules";
    import {setFormErrors} from "@/utils/form";
    import {AllowedLocationIdsCacheMixin} from "@/app/mixins/AllowedLocationIdsCacheMixin";

    export default {
        name: "StockLocationLocksList",
        components: {FormFields},
        mixins: [RouteParamsMapperMixin, FormFetchItemMixin, AllowedLocationIdsCacheMixin],
        props: {
            lock: {
                type: Object,
                default: () => ({})
            },
            lockType: {
                type: String,
                default: StockLocationLockType.MANUFACTURER
            },
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new StockLocationLockForm,
            formRender: new StockLocationLockRender,
            valid: true,
            possibleItems: [],
            items: [{itemId: null, instanceId: null}],
            loading: false,
            StockLocationLockType: StockLocationLockType,
            StockLocationLockIcons: StockLocationLockIcons,
            has: has,
            instances: {},
            formRules: formRules
        }),
        createdOrActivated: function () {
            this.fetchPossibleItems();

            if (this.isEdit) {
                const instancePromises = [];
                this.items = [];
                if (this.lockType === StockLocationLockType.INSTANCE) {
                    this.loading = true;
                    const promises = [];
                    this.lock.object_ids.forEach(lockItemId => {
                        promises.push(
                            ProductAPI.getDirectProductIdForInstance(lockItemId).then(productId => {
                                instancePromises.push(
                                    this.fetchInstances(productId)
                                );
                                this.items.push({itemId: productId, instanceId: lockItemId});
                            })
                        );
                    });
                    Promise.all(promises).then(() =>
                        Promise.all(instancePromises).then(() => this.items.push({itemId: null, instanceId: null}))
                    ).catch(err => {
                        this.snack(err);
                        this.$router.push(this.parentRoute);
                    }).finally(() => this.loading = false);
                } else {
                    this.lock.object_ids.forEach(lockItemId => {
                        this.items.push({itemId: lockItemId, instanceId: null});
                    });
                    this.items.push({itemId: null, instanceId: null});
                }
            }
            this.instances = {};
        },
        computed: {
            lockItemLabel: function () {
                const mainLockType = this.lockType === StockLocationLockType.INSTANCE ? StockLocationLockType.PRODUCT : this.lockType;
                return this.$t('stocks.locations.locks.lockItems.' + mainLockType);
            },
            parentRoute: function () {
                return '/stocks/' + this.stockId + '/locations/' + this.locationId;
            }
        },
        watch: {
            lockType: function () {
                this.items = [{itemId: null, instanceId: null}];
                this.fetchPossibleItems();
            }
        },
        methods: {
            formFetchItem: async function () {
                return {data: this.lock};
            },
            fetchPossibleItems: function () {
                this.loading = true;
                let promise;
                switch (this.lockType) {
                case StockLocationLockType.MANUFACTURER:
                    promise = this.fetchManufacturers();
                    break;
                case StockLocationLockType.PRODUCT_TYPE:
                    promise = this.fetchProductTypes();
                    break;
                default:
                    promise = this.fetchProducts();
                }
                promise.catch(err => {
                    this.snack(err);
                    this.$router.push(this.parentRoute);
                }).finally(() => {
                    this.loading = false;
                });
            },
            fetchManufacturers: function () {
                return ManufacturerAPI.getAllPages().then(response => {
                    this.possibleItems = response.data.items.map(el => ({
                        text: el.name,
                        value: el.id
                    }));
                });
            },
            fetchProductTypes: function () {
                return ProductTypeAPI.getAllPages().then(response => {
                    this.possibleItems = response.data.items.filter(el => !el.default);
                    this.possibleItems = this.possibleItems.map(el => ({
                        text: el.name,
                        value: el.id
                    }));
                });
            },
            fetchProducts: function () {
                return ProductAPI.getAllPages().then(response => {
                    this.possibleItems = response.data.items.map(el => ({
                        text: this.$options.filters.productLabel(el),
                        value: el.id
                    }));
                });
            },
            fetchInstances: function (productId) {
                return ProductAPI.getAllInstancesAllPages(productId).then(response => {
                    const promises = [];
                    response.data.items.forEach(instanceShort => {
                        promises.push(ProductAPI.getInstanceWOProduct(instanceShort.id));
                    });
                    return Promise.all(promises).then(responses => {
                        this.$set(this.instances, productId, {loading: false, items: responses
                            .map(response => ({
                                text: this.$options.filters.instanceTypeLabel(response.data),
                                value: response.data.id,
                            }))
                        });
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push(this.parentRoute);
                    });
                });
            },
            itemChanged: function (index) {
                const itemId = this.items[index].itemId;
                if (this.items.filter(item => item.itemId === itemId).length > 1 && this.lockType !== StockLocationLockType.INSTANCE) {
                    this.items[index].errors = [this.$t('stocks.locations.locks.lockAlreadySelected')];
                } else {
                    this.items[index].errors = [];
                }
                if (this.items.filter(item => item.itemId === null).length === 0) {
                    this.items.push({itemId: null, instanceId: null});
                }
                if (this.lockType === StockLocationLockType.INSTANCE) {
                    if (!has(this.instances, itemId)) {
                        this.$set(this.instances, itemId, {loading: true, items: []});
                    }
                    if (this.instances[itemId].items.length === 0) {
                        this.fetchInstances(itemId);
                    }
                }
            },
            instanceChanged: function (index) {
                const instanceId = this.items[index].instanceId;
                if (this.items.filter(item => item.instanceId === instanceId).length > 1) {
                    this.items[index].instanceErrors = [this.$t('stocks.locations.locks.lockAlreadySelected')];
                } else {
                    this.items[index].instanceErrors = [];
                }
            },
            removeItem: function (index) {
                if (this.items.length === 1) {
                    this.snack('stocks.locations.locks.unableToRemoveLastLock');
                    return;
                }
                this.items.splice(index, 1);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const data = {...this.form};
                data.lock_items_ids = this.items
                    .filter(item => this.lockType === StockLocationLockType.INSTANCE ? item.instanceId !== null: item.itemId !== null
                    ).map(item => {
                        if (this.lockType === StockLocationLockType.INSTANCE) {
                            return item.instanceId;
                        } else {
                            return item.itemId;
                        }
                    });
                if (this.isEdit) {
                    StockAPI.updateLocationLock(this.stockId, this.locationId, this.lock.lock_type, data).then(() => {
                        this.advancedSnack({
                            text: 'stocks.locations.locks.update.done',
                            params: [this.$t('stocks.locations.locks.lockTypes.' + this.lock.lock_type)]
                        });
                        this.clearAllowedLocationIdsCacheByStock(this.stockId);
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                    }).catch(setFormErrors.bind(this))
                        .finally(() => this.loading = false);
                } else {
                    StockAPI.createLocationLock(this.stockId, this.locationId, this.lockType, data).then(() => {
                        this.advancedSnack({
                            text: 'stocks.locations.locks.create.done',
                            params: [this.$t('stocks.locations.locks.lockTypes.' + this.lockType)]
                        });
                        this.clearAllowedLocationIdsCacheByStock(this.stockId);
                        this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        this.$router.push(this.parentRoute);
                    }).catch(setFormErrors.bind(this))
                        .finally(() => this.loading = false);
                }
            }
        }
    };
</script>

<style scoped>

</style>
