<template>
  <div>
    <v-alert
      v-if="conflict !== null"
      type="error"
      outlined
    >
      {{ $t('products.instances.barcodes.conflict', [conflict.model ? conflict.name : '']) }}
      <span v-if="conflict.model !== undefined">
        <router-link
          v-if="isChief"
          :to="'/products/' + conflict.id"
        >
          <v-btn
            outlined
            class="ml-2"
          >
            {{ $t('base.view') }}
          </v-btn>
        </router-link>
      </span>
      <span v-else-if="conflict.code !== undefined">
        {{ ' ' + locationLabel(conflict) }}
      </span>
      <span v-else>
        ...
      </span>
    </v-alert>
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.instances.barcodes."
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {
        ProductInstanceBarcodeForm,
        ProductInstanceBarcodeRender
    } from "@/app/products/instances/barcodes/definitions/productInstanceBarcode.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {ProductAPI} from "@/api/ProductAPI";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {CodeType} from "@/enum/code_type";
    import {locationLabel} from "@/utils/string";
    import {ACLMixin} from "@/app/mixins/ACLMixin";

    export default {
        name: "ProductInstanceBarcodeCreateUpdateComponent",
        components: {FormFields},
        mixins: [FormFetchItemMixin, ACLMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            productId: {
                type: Number,
                default: null
            },
            productName: {
                type: String,
                default: null
            },
            code: {
                type: [String, Number],
                default: undefined
            },
            instanceId: {
                type: Number,
                default: null
            },
            isInModal: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new ProductInstanceBarcodeForm,
            formRender: new ProductInstanceBarcodeRender,
            valid: true,
            loading: false,
            conflict: null,
            locationLabel: locationLabel
        }),
        createdOrActivated: function () {
            if (!this.isEdit) {
                const queryCode = this.$route.query.code;
                if (queryCode) {
                    this.form.code = decodeURIComponent(queryCode);
                }
            }
        },
        methods: {
            formFetchItem: function () {
                return ProductAPI.getInstanceBarcode(this.productId, this.instanceId, this.code);
            },
            submit: function () {
                if (!this.valid) {
                    this.valid = this.$refs.form.validate();
                    if (!this.valid) {
                        return;
                    }
                }
                this.loading = true;
                if (this.isEdit) {
                    const code = this.code;
                    // Intentionaly convert numbers to strings and vice versa
                    if (code != this.form.code) {
                        // code changed, perform delete and create new
                        const oldCode = code;
                        ProductAPI.createInstanceBarcode(this.productId, this.instanceId, this.form)
                            .then(() => {
                                ProductAPI.deleteInstanceBarcode(this.productId, this.instanceId, oldCode)
                                    .then(() => {
                                        this.handleUpdateDone(code);
                                    }).catch(this.snack)
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            }).catch(err => {
                                // error is not handled, need to get errors manually
                                this.handleCreateError(err);
                                this.loading = false;
                            });
                    } else {
                        ProductAPI.updateInstanceBarcode(this.productId, this.instanceId, code, this.form)
                            .then(() => {
                                this.handleUpdateDone(code);
                            }).catch(setFormErrors.bind(this))
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                } else {
                    ProductAPI.createInstanceBarcode(this.productId, this.instanceId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.instances.barcodes.create.done',
                                params: [this.form.code, this.productName]
                            });
                            IndexedDB.get(CachePath.instanceBarcodes, this.instanceId).then(instanceBarcodes => {
                                instanceBarcodes.push(this.form);
                                IndexedDB.save(CachePath.instanceBarcodes, this.instanceId, instanceBarcodes);
                                this.$emit('barcodeUpdated');
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                        }).catch(err => {
                            // error is not handled, need to get errors manually
                            this.handleCreateError(err);
                        }).finally(() => {
                            this.loading = false;
                        });
                }
            },
            handleUpdateDone: function (code) {
                this.advancedSnack({
                    text: 'products.instances.barcodes.update.done',
                    params: [code, this.productName]
                });
                IndexedDB.clearByKey(CachePath.instanceBarcodes, this.instanceId);
                IndexedDB.clearByKey(CachePath.barcodes, code);
                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                this.$emit('barcodeUpdated');
            },
            handleCreateError: function (err) {
                if (err.response.status === 409) {
                    const data = err.response.data;
                    this.$set(this.formRender.code, 'errors', [this.$t('products.instances.barcodes.status.409')]);
                    this.conflict = true;
                    if (data.type === CodeType.PRODUCT_INSTANCE) {
                        this.conflict = data.object_info.product;
                    } else if (data.type === CodeType.STOCK_LOCATION) {
                        this.conflict = data.object_info;
                    }
                } else {
                    setFormErrors.call(this, err);
                }
            }
        }
    };
</script>

<style scoped>

</style>
