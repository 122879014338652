<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.attributes."
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import {setFormErrors} from "@/utils/form";
    import {
        ProductAttributeForm,
        ProductAttributeRender
    } from "@/app/products/attributes/definitions/productAttribute.form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";

    export default {
        name: "ProductAttributeCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new ProductAttributeForm,
            formRender: {},
            valid: true,
            loading: false,
            productName: ''
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.productName !== '') {
                    if (this.isEdit) {
                        title = this.$t('products.attributes.update.title', [this.form.key, this.productName]);
                    } else {
                        title = this.$t('products.attributes.create.title', [this.productName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        created: function () {
            this.formRender = new ProductAttributeRender(this.isEdit);
            this.$set(this.formRender.key, 'loading', true);
            API.getAllPossibleAttributes()
                .then(response => {
                    API.getAllAttributes(this.productId)
                        .then(secondResponse => {
                            const usedKeys = secondResponse.data.map(attribute => attribute.key.toLowerCase());
                            this.formRender.key.autocomplete.items = response.data.filter(attributeKey => !usedKeys.includes(attributeKey.toLowerCase()));
                        }).finally(() => this.$set(this.formRender.key, 'loading', false));
                }).catch(() => this.$set(this.formRender.key, 'loading', false));
        },
        createdOrActivated: function () {
            API.get(this.productId)
                .then(response => {
                    this.productName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/products');
                });
        },
        methods: {
            formFetchItem: function () {
                return API.getAttribute(this.productId, this.attributeKey);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const attributeKey = this.attributeKey;
                    API.updateAttribute(this.productId, attributeKey, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.attributes.update.done',
                                params: [this.form.key, this.productName]
                            });
                            this.clearInstanceCachesByProduct(this.productId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/' + this.productId);
                        }).catch(err => {
                            if (err === 412) {
                                this.snack('products.status.412');
                                this.$router.push('/products/' + this.productId);
                            } else {
                                setFormErrors.call(this, err);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    API.createAttribute(this.productId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.attributes.create.done',
                                params: [this.form.key, this.productName]
                            });
                            this.clearInstanceCachesByProduct(this.productId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/' + this.productId);
                        }).catch(err => {
                            if (err === 412) {
                                this.snack('products.status.412');
                                this.$router.push('/products/' + this.productId);
                            } else {
                                setFormErrors.call(this, err);
                            }
                        }).finally(() => {
                            this.loading = false;
                        });
                }
            },
        }
    };
</script>

<style scoped>

</style>
