<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-alert
      v-if="isManufacturerSale && form.manufacturer_id"
      type="info"
    >
      <span> {{ $t('buyers.wholesaleDiscounts.willBeAppliedOn.start') }} </span>
      <v-progress-circular
        v-if="affectedProductCount === null"
        indeterminate
      />
      <span v-else> {{ affectedProductCount }} </span>
      <span> {{ $t('buyers.wholesaleDiscounts.willBeAppliedOn.products') }} </span>
      <v-btn
        class="ml-2"
        outlined
        :to="'/products?manufacturer.id=' + form.manufacturer_id"
      >
        <v-icon>
          $openItem
        </v-icon>
      </v-btn>
    </v-alert>
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="buyers.wholesaleDiscounts."
          />
          <v-flex
            v-if="form.product_id && !isManufacturerSale"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <div class="d-flex">
              <div class="ml-4 mr-16">
                <span class="text-caption">{{ $t('buyers.wholesaleDiscounts.original_sell_price') + ': ' }}</span>
                <ProductSellPrice :product="ProductCache[form.product_id]" />
              </div>
              <div>
                <span class="text-caption">{{ $t('buyers.wholesaleDiscounts.discounted_sell_price') + ': ' }}</span>
                <ProductSellPrice
                  :product="ProductCache[form.product_id]"
                  :price-transform="Product.computeDiscountedPrice.bind(this, form, false)"
                />
              </div>
            </div>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :plain="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2">
      <ManufacturerCreateUpdate
        v-if="showCreateManufacturer"
        id="manufacturerForm"
        ref="manufacturerForm"
        class="ma-1"
        is-component
      />
    </div>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {BuyerDiscountForm, BuyerDiscountRender} from "@/app/buyers/definitions/buyerDiscount.form";
    import {tabTitle} from "@/utils/string";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {setFormErrors} from "@/utils/form";
    import {WholesaleDiscountType} from "@/enum/wholesale_discount_type";
    import {WholesaleDiscountSaleType} from "@/enum/wholesale_discount_sale_type";
    import ManufacturerCreateUpdate from "@/app/manufacturers/ManufacturerCreateUpdate.view.vue";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import {scrollTo} from "@/service/Vuetify";
    import {numberToFixed} from "@/utils/number";
    import {ProductAPI} from "@/api/ProductAPI";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import {ReactiveProductCacheMixin} from "@/app/mixins/ReactiveProductCacheMixin";
    import ProductSellPrice from "@/app/products/components/ProductSellPrice.vue";
    import {Product} from "@/utils/product";

    export default {
        name: "BuyerWholesaleDiscountCreateUpdate",
        components: {ProductSellPrice, ManufacturerCreateUpdate, FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin, EventsListenerMixin, ReactiveProductCacheMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new BuyerDiscountForm,
            formRender: new BuyerDiscountRender,
            valid: true,
            loading: false,
            buyerName: '',
            showCreateManufacturer: false,
            affectedProductCount: null,
            Product: Product
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.buyerName !== '') {
                    if (this.isEdit) {
                        title = this.$t('buyers.wholesaleDiscounts.update.title', [this.buyerName]);
                    } else {
                        title = this.$t('buyers.wholesaleDiscounts.create.title', [this.buyerName]);
                    }
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            events: function () {
                return {
                    'create-manufacturer': this.onCreateManufacturer,
                    'create-manufacturer-created': this.onCreateManufacturerCreated,
                    'create-manufacturer-cancelled': () => this.showCreateManufacturer = false,
                };
            },
            isManufacturerSale: function () {
                return this.form.type === WholesaleDiscountType.MANUFACTURER_SALE;
            }
        },
        watch: {
            isManufacturerSale: function () {
                this.$set(this.formRender.product_id, 'show', !this.isManufacturerSale);
                this.$set(this.formRender.product_id, 'required', !this.isManufacturerSale);

                this.$set(this.formRender.manufacturer_id, 'show', this.isManufacturerSale);
                this.$set(this.formRender.manufacturer_id, 'required', this.isManufacturerSale);
            },
            'form.manufacturer_id': function () {
                this.fetchAffectedProductCount();
            },
            'form.product_id': function (productId) {
                this.cacheProduct(ProductAPI.get(productId), productId);
            }
        },
        createdOrActivated: function () {
            BuyerAPI.get(this.buyerId)
                .then(response => {
                    this.buyerName = response.data.name;
                }).catch(err => {
                    this.snack(err);
                    this.$router.push('/buyers');
                });
        },
        methods: {
            formFetchItem: function () {
                return BuyerAPI.getDiscount(this.buyerId, this.discountId)
                    .then(response => {
                        const discount = response.data;
                        if (discount.sale_type === WholesaleDiscountSaleType.DISCOUNT_PERCENT) {
                            discount.value *= 100;
                        } else {
                            discount.value = numberToFixed(discount.value, 2);
                        }
                        return response;
                    });
            },
            fetchAffectedProductCount: function () {
                this.affectedProductCount = null;
                const manufacturerId = this.form.manufacturer_id;
                if (!manufacturerId)
                    return;
                ProductAPI.getAll({
                    itemsPerPage: 1,
                    filter: APIFilters.makeFilter({
                        [APIFilterOP.EQUALS]: {
                            'manufacturer.id': manufacturerId
                        }
                    })
                }).then(response => {
                    this.affectedProductCount = response.data.item_count;
                }).catch(() => {
                    // fail silently
                });
            },
            onCreateManufacturer: function () {
                this.showCreateManufacturer = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.manufacturer_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('manufacturerForm');
                });
            },
            onCreateManufacturerCreated: function (manufacturerId) {
                this.showCreateManufacturer = false;
                this.form.manufacturer_id = Number.parseInt(manufacturerId, 10);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                // create form copy because I have to change value and user might think there is some kind of error
                const form = {...this.form};
                if (this.form.type === WholesaleDiscountType.PRODUCT_SALE) {
                    form.manufacturer_id = null;
                }
                if (this.form.type === WholesaleDiscountType.MANUFACTURER_SALE) {
                    form.product_id = null;
                }
                if (form.sale_type === WholesaleDiscountSaleType.DISCOUNT_PERCENT) {
                    form.value /= 100;
                }
                if (this.isEdit) {
                    BuyerAPI.updateDiscount(this.buyerId, this.discountId, form)
                        .then(() => {
                            this.snack('buyers.wholesaleDiscounts.update.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/buyers/' + this.buyerId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => this.loading = false);
                } else {
                    BuyerAPI.createDiscount(this.buyerId, form)
                        .then(() => {
                            this.advancedSnack('buyers.wholesaleDiscounts.create.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/buyers/' + this.buyerId);
                        }).catch(setFormErrors.bind(this))
                        .finally(() => this.loading = false);
                }
            }
        }
    };
</script>

<style scoped>

</style>
