<template>
  <div>
    <x-toolbar />
    <v-container fluid>
      <template
        v-if="lists.length === 0 && loading"
      >
        <v-skeleton-loader
          v-for="index in 3"
          :key="index"
          type="list-item-avatar-three-line"
          class="mb-2"
        />
      </template>
      <div>
        <v-card
          v-for="(list, index) of lists"
          :key="list.uid"
          class="d-flex mb-2 overflow-y-auto"
        >
          <v-item-group class="d-flex flex-column">
            <v-btn
              icon
              color="secondary"
              :disabled="index === 0"
              @click="moveUp(index)"
            >
              <v-icon>
                $moveItemUp
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              icon
              color="secondary"
              :disabled="index === lists.length - 1"
              @click="moveDown(index)"
            >
              <v-icon>
                $moveItemDown
              </v-icon>
            </v-btn>
          </v-item-group>
          <DashboardItemConfig
            :item="list"
            :possible-values="possibleValues"
          />
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                class="ml-auto ma-1"
                @click="deleteItem(index)"
                v-on="on"
              >
                <v-icon>
                  $deleteDashboardItem
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('base.delete') }}</span>
          </v-tooltip>
        </v-card>
        <v-btn
          v-for="(button, index) in buttons.filter(but => !but.condition || but.condition())"
          :key="'btn-' + index"
          :outlined="button.outlined"
          :color="button.color"
          class="ml-3 my-2"
          :class="button.extraClass"
          :loading="loading"
          @click="button.action"
        >
          <v-icon class="mr-2">
            {{ button.icon }}
          </v-icon>
          {{ $t(button.label) }}
        </v-btn>
        <input
          ref="uploader"
          :value="chosenFile"
          class="d-none"
          type="file"
          accept="text/plain"
          @click="chosenFile = null"
          @change="onFilePicked"
        >
      </div>
    </v-container>
    <ConfirmDeleteDialog
      v-if="confirmDialog && deletedIndex !== null"
      :show.sync="confirmDialog"
      :text="lists[deletedIndex].props.label"
      @confirm-delete="reallyDelete"
    />
  </div>
</template>

<script>
    import DashboardItemConfig from "@/app/homepage/components/DashboardItemConfig.component";
    import {Dashboard} from "@/service/Dashboard";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {TaskFilterMixin} from "@/app/mixins/TaskFilterMixin";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";
    import {taskFilterColumns} from "@/app/tasks/definitions/taskFilterColumns";
    import * as Export from "@/service/Export";
    import {ISO_8601_DATE_TIME_FORMAT} from "@/utils/datetime";

    export default {
        name: "DashboardConfig",
        components: {DashboardItemConfig, ConfirmDeleteDialog},
        mixins: [TaskFilterMixin, ACLMixin],
        data: () => ({
            loading: true,
            lists: [],
            deletedIndex: null,
            confirmDialog: false,
            possibleValues: taskFilterColumns,
            listMoved: false,
            chosenFile: null
        }),
        createdOrActivated: function () {
            Promise.all([
                this.load(),
                this.fetchPossibleValues()
            ]).finally(() => {
                this.loading = false;
            });
        },
        computed: {
            buttons: function () {
                return [
                    {
                        color: "accent",
                        label: "base.save",
                        icon: "$saveItem",
                        action: this.saveAfterEventCycle
                    },
                    {
                        color: "secondary",
                        label: "homepage.config.addTaskList",
                        icon: "$addItem",
                        action: this.addTaskList,
                        outlined: true
                    },
                    {
                        condition: () => this.isChief,
                        color: "secondary",
                        label: "homepage.config.addOrdersStats",
                        icon: "$addItem",
                        action: this.addOrdersStats,
                        outlined: true
                    },
                    {
                        color: "secondary",
                        label: "homepage.config.export.title",
                        icon: "$export",
                        action: this.exportLists,
                        outlined: true
                    },
                    {
                        color: "secondary",
                        label: "homepage.config.import.title",
                        icon: "$import",
                        action: () => this.$refs.uploader.click(),
                        outlined: true
                    },
                    {
                        color: "error",
                        label: "homepage.config.reset",
                        icon: "$restoreDefaultItems",
                        action: this.resetLists,
                        extraClass: "float-right",
                        outlined: true
                    }
                ];
            }
        },
        methods: {
            addTaskList: function () {
                this.lists.push({
                    uid: Math.random(),
                    showOnDesktop: true,
                    showOnMobile: true,
                    type: 'taskList',
                    props: {
                        label: this.$t('homepage.config.newList'),
                        autoRefresh: false,
                        autoRefreshIn: 60,
                        filter: null
                    }
                });
            },
            addOrdersStats: function () {
                this.lists.push({
                    uid: Math.random(),
                    showOnDesktop: true,
                    showOnMobile: true,
                    type: 'ordersStats',
                    props: {
                        label: this.$t('homepage.stats.orders.label'),
                        autoRefresh: false,
                        autoRefreshIn: 60
                    }
                });
            },
            moveUp: function (index) {
                const list = this.lists.splice(index, 1);
                this.lists.splice(index - 1, 0, list[0]);
                this.listMoved = true;
            },
            moveDown: function (index) {
                const list = this.lists.splice(index, 1);
                this.lists.splice(index + 1, 0, list[0]);
                this.listMoved = true;
            },
            deleteItem: function (index) {
                this.deletedIndex = index;
                this.confirmDialog = true;
            },
            reallyDelete: function () {
                this.confirmDialog = false;
                this.lists.splice(this.deletedIndex, 1);
            },
            load: function () {
                this.loading = true;
                return Dashboard.loadHomepageConfig()
                    .then(data => {
                        if (data.length === 0) {
                            throw 'not-defined';
                        }
                        data.forEach(list => {
                            list.uid = Math.random();
                        });
                        this.lists = data;
                    }).catch(reason => {
                        if (reason === 'not-defined') {
                            this.lists = this.getDefaultLists();
                        } else {
                            this.snack('homepage.config.unableToLoad');
                        }
                    });
            },
            saveAfterEventCycle: function () {
                setTimeout(this.save);
            },
            save: function () {
                this.loading = true;
                if (this.listMoved) {
                    // reset dashboard grid
                    this.lists.forEach(list => {
                        delete list.layout;
                        delete list.uid;
                    });
                }
                Dashboard.saveHomepageConfig(this.lists)
                    .then(() => {
                        const homepageRoute = this.$router.getRoutes().find(route => route.path === '');
                        homepageRoute.meta.uuid = homepageRoute.meta.uuid + 1 || 1;
                        this.$router.push('/');
                    }).catch(() => {
                        this.snack('homepage.config.unableToSave');
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            exportLists: function () {
                const configString = JSON.stringify({
                    version: Dashboard.version,
                    config: this.lists
                });
                const formattedDate = this.$moment().format(ISO_8601_DATE_TIME_FORMAT);
                Export.url('data:text/plain;charset=utf-8,%EF%BB%BF' + encodeURIComponent(configString), 'homepage-config-' + formattedDate);
                this.snack('homepage.config.export.done');
            },
            onFilePicked: function (event) {
                const files = event.target.files;
                const file = files[0];
                if (file !== undefined) {
                    this.loading = true;
                    const fileReader = new FileReader();
                    fileReader.readAsText(file);
                    fileReader.addEventListener('load', () => {
                        const loadedConfig = fileReader.result;
                        try {
                            let savedConfig = JSON.parse(loadedConfig);
                            if (Object.keys(savedConfig).length === 0) {
                                this.onFileError();
                            } else {
                                if (savedConfig.version === undefined) {
                                    savedConfig.config = savedConfig;
                                    savedConfig.version = 1;
                                }
                                Dashboard.convertVersion(savedConfig.config, savedConfig.version);
                                this.lists = savedConfig.config;
                                this.snack('homepage.config.import.done');
                            }
                        } catch (error) {
                            window.console.error(error);
                            this.onFileError();
                        }
                        this.loading = false;
                    });
                } else {
                    this.onFileError();
                }
            },
            onFileError: function () {
                this.snack('homepage.config.import.fail');
            },
            resetLists: function () {
                this.loading = true;
                Dashboard.saveHomepageConfig({})
                    .then(() => {
                        this.lists = this.getDefaultLists();
                        this.snack('homepage.config.resetDone');
                    }).catch(() => {
                        this.snack('homepage.config.unableToSave');
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
    // TODO drag'n'drop order
</script>

<style scoped>

</style>
