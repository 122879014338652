<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('stocks.owners.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="stocks.owners."
          >
            <template #ico-append-outer>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!allowAres || loading"
                    :loading="loadingAres"
                    fab
                    small
                    bottom
                    color="secondary"
                    @click="loadAres()"
                    v-on="on"
                  >
                    <v-icon>$aresValidation</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('base.ares.load') }}</span>
              </v-tooltip>
            </template>
          </FormFields>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-subheader>{{ $t('stocks.owners.billing_address') }}</v-subheader>
          </v-flex>
          <FormAddress
            :form="form.billing_address"
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-subheader>{{ $t('stocks.owners.bank_account') }}</v-subheader>
          </v-flex>
          <FormBankAccount
            :form="form.bank_account"
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {OwnerForm, OwnerRender} from "@/app/stocks/owners/definitions/owner.form";
    import FormAddress from "@/app/components/form/FormAddress.component";
    import FormBankAccount from "@/app/components/form/FormBankAccount.component";
    import {StockAPI} from "@/api/StockAPI";
    import {EventBus} from "@/service/EventBus";
    import {distributeBAN} from "@/app/components/form/definitions/bankAccount.form";
    import {tabTitle} from "@/utils/string";
    import {setFormErrors} from "@/utils/form";
    import {LoadAresMixin} from "@/app/mixins/LoadAresMixin";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "StockOwnerCreateUpdate",
        components: {FormBankAccount, FormAddress, FormFields},
        mixins: [FormFetchItemMixin, LoadAresMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new OwnerForm,
            formRender: new OwnerRender,
            valid: true,
            loading: false,
            loadingAres: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('stocks.owners.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return StockAPI.getOwner(this.ownerId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                distributeBAN(this.form.bank_account.full_number, this.form.bank_account);
                if (this.isEdit) {
                    StockAPI.updateOwner(this.ownerId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'stocks.owners.update.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    StockAPI.createOwner(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'stocks.owners.create.done',
                                params: [this.form.name]
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-owner-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.back();
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-owner-cancelled');
            }
        }
    };

    // TODO update
</script>

<style scoped>

</style>
