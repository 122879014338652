<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('suppliers.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="suppliers."
          >
            <template #ico-append-outer>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!allowAres || loading"
                    :loading="loadingAres"
                    fab
                    small
                    bottom
                    color="secondary"
                    @click="loadAres()"
                    v-on="on"
                  >
                    <v-icon>$aresValidation</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('base.ares.load') }}</span>
              </v-tooltip>
            </template>
          </FormFields>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-subheader>{{ $t('suppliers.billing_address') }}</v-subheader>
          </v-flex>
          <FormAddress
            :form="form.billing_address"
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :disabled="loadingAres"
              :plain="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $saveItem
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {SupplierForm, SupplierRender} from "@/app/suppliers/definitions/supplier.form";
    import {tabTitle} from "@/utils/string";
    import {SupplierAPI} from "@/api/SupplierAPI";
    import FormAddress from "@/app/components/form/FormAddress.component";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {LoadAresMixin} from "@/app/mixins/LoadAresMixin";
    import {EventBus} from "@/service/EventBus";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "SupplierCreateUpdate",
        components: {FormFields, FormAddress},
        mixins: [FormFetchItemMixin, LoadAresMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new SupplierForm,
            formRender: new SupplierRender,
            valid: true,
            loading: false,
            loadingAres: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('suppliers.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return SupplierAPI.get(this.supplierId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const id = this.supplierId;
                    SupplierAPI.update(id, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'suppliers.update.done',
                                params: [this.form.name]
                            });

                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/suppliers');

                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    SupplierAPI.create(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'suppliers.create.done',
                                params: [this.form.name]
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-supplier-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/suppliers');
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-supplier-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
