import { render, staticRenderFns } from "./RenameObjectForm.component.vue?vue&type=template&id=74943380&scoped=true"
import script from "./RenameObjectForm.component.vue?vue&type=script&lang=js"
export * from "./RenameObjectForm.component.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_webpack@5.96.1__lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.96.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74943380",
  null
  
)

export default component.exports