<template>
  <div>
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="!isInView"
            class="subheading"
          >
            {{ $t('buyers.addresses.create.titleSimple') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="buyers.addresses."
          />
          <FormAddress
            :form="form.address"
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {BuyerAddressForm, BuyerAddressRender} from "@/app/buyers/definitions/buyerAddress.form";
    import {BuyerAPI} from "@/api/BuyerAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import FormAddress from "@/app/components/form/FormAddress.component";
    import {EventBus} from "@/service/EventBus";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "BuyerAddressCreateUpdateComponent",
        components: {FormAddress, FormFields},
        mixins: [FormFetchItemMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            buyerAddressId: {
                type: Number,
                default: null
            },
            isInView: {
                type: Boolean,
                default: false
            },
            buyerId: {
                type: Number,
                default: null
            }
        },
        data: () => ({
            form: new BuyerAddressForm,
            formRender: new BuyerAddressRender,
            valid: true,
            loading: false
        }),
        methods: {
            formFetchItem: function () {
                return BuyerAPI.getAddress(this.buyerId, this.buyerAddressId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    BuyerAPI.updateAddress(this.buyerId, this.buyerAddressId, this.form)
                        .then(() => {
                            this.snack('buyers.addresses.update.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('buyer-address-updated');
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    BuyerAPI.createAddress(this.buyerId, this.form)
                        .then(response => {
                            this.snack('buyers.addresses.create.done');
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('buyer-address-updated', getIdFromLocation(response));
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-buyer-address-cancelled');
            }

        }
    };
</script>

<style scoped>

</style>
