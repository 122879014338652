<template>
  <div>
    <x-toolbar
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="carriers."
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import {CarrierForm, CarrierFormRender} from "@/app/carriers/definitions/carrier.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {setFormErrors} from "@/utils/form";
    import {tabTitle} from "@/utils/string";

    export default {
        name: "CarrierCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
        },
        data: () => ({
            form: new CarrierForm,
            formRender: new CarrierFormRender,
            valid: true,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('carriers.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return ShipmentCarrierAPI.get(this.carrierId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    ShipmentCarrierAPI.update(this.carrierId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'carriers.update.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ShipmentCarrierAPI.create(this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'carriers.create.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.back();
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            }
        }
    };
</script>

<style scoped>

</style>
