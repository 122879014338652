import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-1"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VIcon,{staticClass:"mb-1"},[_vm._v(" $closeItem ")]),_c('span',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.close.label'))+" ")]),_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"xs12":""}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VDivider,{staticClass:"my-3"}),_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"tasks.externalOrder.close."}})],1)],1)],1),_c('div',[_c(VBtn,{attrs:{"plain":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.close()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $closeItem ")]),_vm._v(" "+_vm._s(_vm.$t('tasks.externalOrder.close.label'))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }