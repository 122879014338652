<template>
  <div>
    <x-toolbar />
    <v-card>
      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="tasks.stockPicking."
          />
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <TaskChooseItems
              :chosen-items.sync="chosenItems"
              :valid.sync="validItems"
              title-path="tasks.stockPicking.itemsToPick"
              :readonly="loading"
              :only-in-substock-id="form.subordinate_stock_id"
            />
          </v-flex>
          <v-flex
            v-if="form.subordinate_stock_id"
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttributes
              :task-type="type"
              :stock-id="form.stock_id"
              :sub-stock-id="form.subordinate_stock_id"
              :submit-trigger="attributesSubmitTrigger"
              override-possible
              inline
              @update:valid="val => validAttributes = val"
              @update-submit-callback="callback => attributesSubmitCallback = callback"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <TaskAttachmentUpload
              v-model="validAttachments"
              :files-to-attach="form.attachments"
            />
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :plain="!validAll"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                $taskStockPicking
              </v-icon>
              {{ $t('tasks.stockPicking.create') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
    <div class="my-2" />
    <BuyerCreateUpdate
      v-if="showCreateBuyer"
      id="buyerForm"
      ref="buyerForm"
      :is-edit="false"
      @buyer-updated="buyerUpdated"
    />
  </div>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component";
    import {
        StockPickingCreateForm,
        StockPickingCreateRender
    } from "@/app/tasks/stockPicking/definitions/stockPickingCreate.form";
    import {formMountedProcedure, setFormErrors} from "@/utils/form";
    import formRules from "@/utils/formRules";
    import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
    import {StockAPI} from "@/api/StockAPI";
    import {TaskShippingType} from "@/enum/task_shipping_type";
    import TaskAttachmentUpload from "@/app/tasks/components/TaskAttachmentUpload.component";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {locationLabel} from "@/utils/string";
    import TaskChooseItems from "@/app/tasks/components/TaskChooseItems.component";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {getIdFromLocation} from "@/utils/url";
    import TaskAttributes from "@/app/tasks/components/taskAttributes/TaskAttributes.component";
    import BuyerCreateUpdate from "@/app/buyers/components/BuyerCreateUpdate.component.vue";
    import {scrollTo} from "@/service/Vuetify";

    export default {
        name: "StockPickingCreate",
        components: {BuyerCreateUpdate, TaskAttributes, TaskAttachmentUpload, FormFields, TaskChooseItems},
        mixins: [TaskFetchItemsMixin],
        data: () => ({
            form: new StockPickingCreateForm(TaskShippingType.PERSONAL_COLLECTION),
            formRender: new StockPickingCreateRender(TaskShippingType.PERSONAL_COLLECTION),
            valid: true,
            validAttachments: true,
            validItems: true,
            validAttributes: true,
            loading: false,
            chosenItems: [{product_id: null, product_instance_id: null, quantity: 1}],
            showCreateBuyer: false,
            formRules: formRules,
            type: taskTypes.STOCK_PICKING,
            attributesSubmitTrigger: 0,
            attributesSubmitCallback: null,
        }),
        computed: {
            events: function () {
                return {
                    'create-buyer': this.onCreateBuyer,
                    'create-buyer-cancelled': () => this.showCreateBuyer = false,
                };
            },
            validAll: function () {
                return this.valid && this.validAttachments && this.validItems && this.validAttributes;
            }
        },
        watch: {
            'form.stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadSubStocks();
                }
            },
            'form.subordinate_stock_id': function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    this.loadLocations();
                }
            },
            'form.shipping_type': function (newValue) {
                if (newValue === TaskShippingType.COURIER) {
                    this.formRender.destination_stock_location_id.readonly = false;
                } else {
                    this.form.destination_stock_location_id = null;
                    this.formRender.destination_stock_location_id.readonly = true;
                    // TODO trigger required
                    delete this.formRender.destination_stock_location_id.errors;
                }
            }
        },
        mounted: function () {
            formMountedProcedure(this);
        },
        methods: {
            loadSubStocks: function () {
                this.$set(this.formRender.subordinate_stock_id, 'loading', true);
                StockAPI.getAllSubstocksForStockAllPages(this.form.stock_id)
                    .then(response => {
                        this.form.subordinate_stock_id = null;
                        this.formRender.subordinate_stock_id.autocomplete.items = response.data.items.map(el => ({
                            text: el.name,
                            value: el.id
                        }));
                    }).finally(() => {
                        this.$set(this.formRender.subordinate_stock_id, 'loading', false);
                    });
            },
            loadLocations: function () {
                if (this.form.shipping_type === TaskShippingType.COURIER) {
                    this.$set(this.formRender.destination_stock_location_id, 'loading', true);
                    StockAPI.getAllSubstockAvailableLocationsAllPages(this.form.stock_id, this.form.subordinate_stock_id)
                        .then(response => {
                            this.formRender.destination_stock_location_id.autocomplete.items = response.data.items.map(el => ({
                                text: locationLabel(el),
                                value: el.id
                            }));
                        }).finally(() => {
                            this.$set(this.formRender.destination_stock_location_id, 'loading', false);
                        });
                }
            },
            onCreateBuyer: function () {
                this.showCreateBuyer = true;
                if (this.$refs.fields !== undefined) {
                    this.$refs.fields.$refs.buyer_id[0].blur();
                }
                this.$nextTick(() => {
                    scrollTo('buyerForm');
                });
            },
            buyerUpdated: function (buyerId) {
                this.showCreateBuyer = false;
                this.form.buyer_id = Number.parseInt(buyerId, 10);
            },
            submit: function () {
                if (!this.validAll) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                this.attributesSubmitTrigger++;
                const data = {...this.form};
                data.items = this.chosenItems
                    .filter(item => {
                        delete item.errors;
                        return item.product_instance_id;
                    });
                TaskStockPickingAPI.create(data)
                    .then(response => {
                        let taskId;
                        const mergedWithExistingTask = response.status === 200;
                        if (mergedWithExistingTask) {
                            taskId = response.data.id;
                        } else {
                            taskId = getIdFromLocation(response);
                        }
                        this.attributesSubmitCallback(taskId, mergedWithExistingTask)
                            .then(() => {
                                if (mergedWithExistingTask) {
                                    this.advancedSnack({
                                        text: 'tasks.stockPicking.createDoneAdded',
                                        params: [taskId]
                                    });
                                } else {
                                    this.snack('tasks.stockPicking.createDone');
                                }
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push(taskLinks[this.type] + '/' + taskId);
                            }).catch(this.snack)
                            .finally(() => this.loading = false);
                    }).catch(err => {
                        setFormErrors.call(this, err);
                        this.loading = false;
                    });
            }
        }
    };
  // TODO only allow to select quantity - blocked
  // TODO create component for shared funcionality with MoveProductsCreate and SubstockTransferCreate
  // TODO CRITICAL how to stock picking from small stock and expedition?
</script>

<style scoped>

</style>
