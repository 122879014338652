import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.locationToCheck !== null)?_c('TaskBarcodeDialog',{attrs:{"show-dialog":_vm.confirmDialog,"title":_vm.locationToCheck.is_mobile ? _vm.$t('tasks.itemsCard.scannedMobileLocation') : _vm.$t('tasks.itemsCard.scannedExpeditionLocation'),"code":_vm.locationToCheck.barcodeInfo.code},on:{"update:showDialog":function($event){_vm.confirmDialog=$event},"update:show-dialog":function($event){_vm.confirmDialog=$event},"cancel":_vm.cancelScanLocation,"confirm":_vm.reallyScanLocation}}):_vm._e(),_c(VTextarea,{staticClass:"mt-2 readerInput-task",class:_vm.scannerInfoIcon.class,attrs:{"auto-grow":"","error":_vm.unknownCode,"error-messages":_vm.readerInputErrors,"hide-details":_vm.readerQueue.length !== 0 || _vm.readerLastScanned !== null,"label":_vm.scannerInfoText,"loading":_vm.barcodeLoading,"persistent-hint":!!_vm.readerLastScanned,"prepend-icon":_vm.scannerInfoIcon.icon,"rows":1,"solo":"","value":_vm.readerInput},on:{"input":_vm.readerInputReset,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;_vm.readerInput = _vm.readerLastScanned}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (evt => _vm.onBarcodeRead(evt.target.value)).apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"d-flex flex-row flex-nowrap align-center justify-center"},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.onReaderClear}},[(![_vm.scannerModes.IDLE,
                    _vm.scannerModes.IDLE_SCAN_PACKING,
                    _vm.scannerModes.IDLE_ORDER_ITEM,
                    _vm.scannerModes.IDLE_LOCATION_OR_ITEM].includes(_vm.scannerMode))?_c(VIcon,[_vm._v(" $removeItem ")]):_vm._e()],1),(_vm.cameraReaderEnabled)?[_c('span',{staticClass:"mx-1"}),_c('CameraCodeReader',{on:{"scan-result":_vm.onBarcodeRead}})]:_vm._e()],2)]},proxy:true}])}),(_vm.readerQueue.length)?_c('div',{staticClass:"d-flex v-messages ml-11 my-2 text--secondary"},[_c('div',{staticClass:"widthFitContent v-messages__message"},[_vm._v(" "+_vm._s(_vm.$t('tasks.queue'))+": ")]),_c('div',{staticClass:"d-flex flex-column flex-wrap ml-1"},_vm._l((_vm.readerQueue),function(item,index){return _c('div',{key:index,staticClass:"v-messages__message"},[_c('strong',[_vm._v(_vm._s(item.quantity)+"x")]),_c('TaskCodeLabel',{attrs:{"code":item.code,"show-x":false}})],1)}),0)]):(_vm.readerLastScanned)?_c('div',{staticClass:"d-flex v-messages ml-11 my-2 text--secondary"},[_c('div',{staticClass:"widthFitContent v-messages__message"},[_vm._v(" "+_vm._s(_vm.$t('tasks.lastScanned'))+" "),_c('TaskCodeLabel',{attrs:{"code":_vm.readerLastScanned}})],1)]):_vm._e(),_c(VExpandTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unknownCode),expression:"unknownCode"}]},_vm._l((_vm.unknownCodeActions),function(action){return _c('div',{key:action.label},[_c(VTextField,{staticClass:"fieldAsButton",attrs:{"solo":"","hide-details":"","readonly":"","prepend-icon":action.icon,"label":_vm.$t('tasks.card.create.' + action.label)},on:{"click":action.action}})],1)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }