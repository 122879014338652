import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:{'ma-1': !_vm.inline},attrs:{"flat":_vm.inline}},[_c(VContainer,{class:{'ma-0 pa-0': _vm.inline},attrs:{"fluid":""}},[_c('div',{staticClass:"text-subtitle-1 ml-2"},[_vm._v(" "+_vm._s(_vm.$t('tasks.attributes.name'))+": ")]),(_vm.overridePossible)?_c(VCheckbox,{staticClass:"ml-2",attrs:{"label":_vm.$t('tasks.attributes.override')},model:{value:(_vm.overrideAttributes),callback:function ($$v) {_vm.overrideAttributes=$$v},expression:"overrideAttributes"}}):_vm._e(),(_vm.loadingData)?_c('div',{staticClass:"mx-3"},[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1):(_vm.readonly)?_vm._l((_vm.formRender),function(field,key){return _c('div',{key:key},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(field.label + ': '))]),(field.checkbox)?[_vm._v(" "+_vm._s(_vm._f("yesOrNo")(_vm.form[key]))+" ")]:[_vm._v(" "+_vm._s(_vm.form[key])+" ")]],2)}):(_vm.form && Object.keys(_vm.form).length)?_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender}}),(_vm.taskId)?_c(VBtn,{attrs:{"plain":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.debouncedSubmit.flush()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1):_vm._e()],1):_c('div',{staticClass:"ml-4 d-flex flex-row align-center"},[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.$t('tasks.attributes.configureFirst'))+" ")]),(_vm.isChief && _vm.stockId && _vm.subStockId)?_c(VBtn,{attrs:{"color":"accent","outlined":"","small":"","to":`/stocks/${_vm.stockId}/substocks/${_vm.subStockId}`}},[_c(VIcon,[_vm._v(" $openItem ")])],1):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }