<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('products.instances.types.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.instances.types."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :plain="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submit()"
            >
              <v-icon
                class="mr-2"
              >
                save
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {InstanceTypeForm, InstanceTypeRender} from "@/app/products/instances/types/definitions/instanceType.form";
    import {ProductAPI} from "@/api/ProductAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import {EventBus} from "@/service/EventBus";
    import {IndexedDB} from "@/service/cache/IndexedDB";
    import {CachePath} from "@/service/cache/CacheConfiguration";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "InstanceTypeCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new InstanceTypeForm,
            formRender: new InstanceTypeRender,
            valid: true,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('products.instances.types.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return ProductAPI.getCustomInstanceType(this.instanceTypeId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    ProductAPI.updateCustomInstanceType(this.instanceTypeId, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'products.instances.types.update.done',
                                params: [this.form.name]
                            });
                            IndexedDB.clearByKey(CachePath.instanceTypes, this.instanceTypeId);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/products/types');
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ProductAPI.createCustomInstanceType(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'products.instances.types.create.done',
                                params: [this.form.name]
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-instance-type-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/products/types');
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-instance-type-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
