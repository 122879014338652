import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VCard,[_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"tasks.locationTransfer."}}),(_vm.form.stock_id)?_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c('TaskAttributes',{attrs:{"task-type":_vm.type,"stock-id":_vm.form.stock_id,"submit-trigger":_vm.attributesSubmitTrigger,"inline":""},on:{"update:valid":val => _vm.validAttributes = val,"update-submit-callback":callback => _vm.attributesSubmitCallback = callback}})],1):_vm._e(),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c('TaskAttachmentUpload',{attrs:{"files-to-attach":_vm.form.attachments},model:{value:(_vm.validAttachments),callback:function ($$v) {_vm.validAttachments=$$v},expression:"validAttachments"}})],1),_c(VFlex,{attrs:{"xs12":""}},[_c(VDivider,{staticClass:"my-3"}),_c(VBtn,{attrs:{"plain":!_vm.validAll,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":function($event){return _vm.submit()}}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $taskLocationTransfer ")]),_vm._v(" "+_vm._s(_vm.$t('tasks.locationTransfer.create'))+" ")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }