import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"my-5"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('FormFields',{attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":_vm.langPath + 'form.'}}),_c(VBtn,{attrs:{"plain":!_vm.valid,"loading":_vm.loading,"type":"submit","color":"accent"},on:{"click":_vm.submitAfterEventCycle}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" update ")]),_vm._v(" "+_vm._s(_vm.$t('form.generate'))+" ")],1)],1)],1)],1),(_vm.items)?_c('div',[_c(VDivider,{staticClass:"my-3"}),_c('x-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items || []}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }