<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('products.types.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="products.types."
          />
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <span class="text-subtitle-1 ml-2">
              {{ $t('products.types.suggestedAttributes.name') }}:
            </span>
          </v-flex>
          <v-flex
            xs12
          >
            <v-layout
              v-for="(suggestedAttribute, index) of attributes"
              :key="suggestedAttribute.id"
              wrap
              align-center
            >
              <v-flex
                xs10
                sm7
                md7
                lg3
                xl2
                class="px-1"
              >
                <v-combobox
                  v-model="suggestedAttribute.key"
                  :items="allAttributes"
                  :error-messages="suggestedAttribute.errors"
                  :label="$t('products.types.suggestedAttributes.key')"
                  prepend-icon="$itemName"
                  :rules="[formRules.maxLen(30)]"
                  @input="attributeChanged(index)"
                />
              </v-flex>
              <v-flex
                xs12
                sm8
                md8
                lg5
                xl3
                class="px-1"
              >
                <v-text-field
                  v-model="suggestedAttribute.description"
                  :rules="[formRules.maxLen(255)]"
                  :label="$t('products.types.suggestedAttributes.description')"
                  prepend-icon="$description"
                />
              </v-flex>
              <v-flex
                xs2
              >
                <v-tooltip
                  bottom
                >
                  <template #activator="{ on }">
                    <v-btn
                      v-show="suggestedAttribute.key !== ''"
                      :disabled="loading"
                      icon
                      v-on="on"
                      @click="removeAttribute(index)"
                    >
                      <v-icon>
                        clear
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('products.types.removeAttribute') }}
                  </span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
          >
            <v-divider
              class="my-3"
            />
            <v-btn
              :plain="!valid"
              :loading="loading"
              type="submit"
              color="accent"
              @click="submitAfterEventCycle"
            >
              <v-icon
                class="mr-2"
              >
                save
              </v-icon>
              {{ $t('form.save') }}
            </v-btn>
          </v-flex>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ProductTypeForm, ProductTypeRender} from "@/app/products/types/definitions/productType.form";
    import {ProductTypeAPI} from "@/api/ProductTypeAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import formRules from "@/utils/formRules";
    import {EventBus} from "@/service/EventBus";
    import {ProductAPI as API} from "@/api/ProductAPI";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "ProductTypeCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new ProductTypeForm,
            formRender: new ProductTypeRender,
            valid: true,
            loading: false,
            attributes: [{key: '', description: ''}],
            formRules: formRules,
            allAttributes: []
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.name !== '') {
                    title = this.$t('products.types.update.title', [this.form.name]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        created: function () {
            const promises = [];
            this.loading = true;
            promises.push(
                API.getAllPossibleAttributes()
                    .then(response => {
                        this.allAttributes = response.data;
                    })
            );

            if (this.isEdit) {
                promises.push(
                    ProductTypeAPI.get(this.typeId)
                        .then(response => {
                            this.attributes = response.data.suggested_attributes
                                .map(attribute => ({
                                    key: attribute.key,
                                    description: attribute.description,
                                    errors: []
                                }));
                            this.attributes.push({key: '', description: ''});
                        }).catch(err => {
                            this.snack(err);
                            this.$router.push('/products/');
                        })
                );
            }
            Promise.all(promises).finally(() => this.loading = false);
        },
        methods: {
            formFetchItem: function () {
                return ProductTypeAPI.get(this.typeId);
            },
            attributeChanged: function (index) {
                const attributeName = this.attributes[index].key;
                if (this.attributes.filter(item => item.key === attributeName).length > 1) {
                    this.attributes[index].errors = [this.$t('products.attributeAlreadyEntered')];
                } else {
                    this.attributes[index].errors = [];
                }
                if (this.attributes.filter(item => item.key === '').length === 0) {
                    this.attributes.push({key: '', description: ''});
                }
            },
            removeAttribute: function (index) {
                if (this.attributes.length === 1) {
                    this.snack('products.unableToRemoveLastAttribute');
                    return;
                }
                this.attributes.splice(index, 1);
            },
            submitAfterEventCycle: function () {
                setTimeout(this.submit);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                const data = {...this.form};
                const suggested_attributes = this.attributes
                    .filter(attribute => {
                        delete attribute.errors;
                        return attribute.key !== '';
                    });
                data.suggested_attributes = suggested_attributes;
                if (this.isEdit) {
                    ProductTypeAPI.update(this.typeId, this.form)
                        .then(() => {
                            ProductTypeAPI.updateSuggestedAttributes(this.typeId, suggested_attributes)
                                .then(() => {
                                    this.advancedSnack({
                                        text: 'products.types.update.done',
                                        params: [this.form.name]
                                    });
                                    this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                    this.$router.push('/products/types');
                                }).catch(setFormErrors.bind(this))
                                .finally(() => {
                                    this.loading = false;
                                });
                        }).catch(err => {
                            setFormErrors.call(this, err);
                            this.loading = false;
                        });
                } else {
                    ProductTypeAPI.create(data)
                        .then(response => {
                            this.advancedSnack({
                                text: 'products.types.create.done',
                                params: [this.form.name]
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-product-type-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/products/types');
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-product-type-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
