<template>
  <v-card
    :class="{'ma-1': !inline}"
    :flat="inline"
  >
    <v-container
      fluid
      :class="{'ma-0 pa-0': inline}"
    >
      <div class="text-subtitle-1 ml-2">
        {{ $t('tasks.attributes.name') }}:
      </div>
      <v-checkbox
        v-if="overridePossible"
        v-model="overrideAttributes"
        class="ml-2"
        :label="$t('tasks.attributes.override')"
      />
      <div
        v-if="loadingData"
        class="mx-3"
      >
        <v-progress-linear
          indeterminate
        />
      </div>
      <template v-else-if="readonly">
        <div
          v-for="(field, key) in formRender"
          :key="key"
        >
          <span class="text-caption">{{ field.label + ': ' }}</span>
          <template v-if="field.checkbox">
            {{ form[key] | yesOrNo }}
          </template>
          <template v-else>
            {{ form[key] }}
          </template>
        </div>
      </template>
      <v-form
        v-else-if="form && Object.keys(form).length"
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <FormFields
          ref="fields"
          :form="form"
          :render="formRender"
        />
        <v-btn
          v-if="taskId"
          :plain="!valid"
          :loading="loading"
          type="submit"
          color="accent"
          @click="debouncedSubmit.flush()"
        >
          <v-icon
            class="mr-2"
          >
            $saveItem
          </v-icon>
          {{ $t('form.save') }}
        </v-btn>
      </v-form>
      <div
        v-else
        class="ml-4 d-flex flex-row align-center"
      >
        <v-subheader>
          {{ $t('tasks.attributes.configureFirst') }}
        </v-subheader>
        <v-btn
          v-if="isChief && stockId && subStockId"
          color="accent"
          outlined
          small
          :to="`/stocks/${stockId}/substocks/${subStockId}`"
        >
          <v-icon>
            $openItem
          </v-icon>
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {AttributesAPI} from "@/api/AttributesAPI";
    import {APIFilterOP, APIFilters} from "@jagu/rest-api-filters-client/apiFilters";
    import {
        TaskAttributesForm,
        TaskAttributesRender
    } from "@/app/tasks/components/taskAttributes/definitions/taskAttributesForm";
    import {TaskAPI} from "@/api/TaskAPI";
    import {debounce} from "lodash";
    import {AttributeDataTypes} from "@/enum/attribute_data_types";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {has} from "@/utils/object";

    export default {
        name: "TaskAttributes",
        components: {FormFields},
        mixins: [ACLMixin],
        props: {
            taskId: {
                type: Number,
                default: null
            },
            taskType: {
                type: String,
                default: null,
            },
            stockId: {
                type: Number,
                default: null,
            },
            subStockId: {
                type: Number,
                default: null
            },
            submitTrigger: {
                type: Number,
                default: 0
            },
            inline: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: false
            },
            // whether user can override existing attributes during creation of new task (for example stock picking)
            overridePossible: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: null,
            formRender: null,
            valid: true,

            allowedAttributes: [],
            existingTaskAttributes: [],
            loadingData: false,
            loading: false,
            attributesLoaded: false,
            dataChanged: false,
            overrideAttributes: false,
        }),
        computed: {
            attributeConfigurationsFilter: function () {
                const filter = [
                    {
                        [APIFilterOP.EQUALS]: {
                            allowed: true
                        }
                    }
                ];
                if (this.taskType) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            task_type: this.taskType
                        }
                    });
                }
                if (this.stockId) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            'stock.id': this.stockId
                        }
                    });
                }
                if (this.subStockId) {
                    filter.push({
                        [APIFilterOP.EQUALS]: {
                            'substock.id': this.subStockId
                        }
                    });
                }
                return filter;
            },
            debouncedSubmit: function () {
                return debounce((taskId) => this.submitCallback(taskId), 2000);
            }
        },
        watch: {
            stockId: {
                handler: function () {
                    this.loadAttributes();
                },
                immediate: true
            },
            subStockId: {
                handler: function () {
                    this.loadAttributes();
                },
                immediate: true
            },
            form: {
                handler: function () {
                    if (this.taskId && this.attributesLoaded) {
                        if (this.loading) {
                            this.dataChanged = true;
                        } else {
                            this.debouncedSubmit(this.taskId);
                        }
                    }
                },
                deep: true,
            },
            valid: function (val) {
                this.$emit('update:valid', val);
            },
            loading: function (newValue) {
                if (!newValue && this.dataChanged) {
                    // data changed when previous update was running, trigger debounced submit now
                    this.debouncedSubmit(this.taskId);
                    this.dataChanged = false;
                }
            },
            submitTrigger: function () {
                this.$emit('update-submit-callback', this.submitCallback);
            }
        },
        activated: function () {
            this.attributesLoaded = false;
            this.loadAttributes();
        },
        beforeDestroy: function () {
            this.debouncedSubmit.flush();
        },
        methods: {
            loadAttributeTemplates: function (templateIds) {
                if (templateIds.length === 0) {
                    this.form = null;
                    this.formRender = null;
                    this.loadingData = false;
                    return Promise.resolve();
                }

                const filter = {
                    [APIFilterOP.IN]: {
                        id: templateIds
                    }
                };
                return AttributesAPI.getAllPages({ filter: APIFilters.makeFilter(filter) })
                    .then(response => {
                        this.allowedAttributes = response.data.items ?? [];
                        this.form = new TaskAttributesForm(this.allowedAttributes);
                        this.formRender = new TaskAttributesRender(this.allowedAttributes);
                    });
            },
            loadAttributes: function () {
                this.loadingData = true;
                return AttributesAPI.getAllConfigurationsAllPages({ filter: APIFilters.makeFilter(this.attributeConfigurationsFilter) })
                    .then(response => {
                        const templateIds = [...new Set((response.data.items ?? []).map(config => config.template_id))];
                        this.loadAttributeTemplates(templateIds)
                            .then(() => {
                                if (this.taskId) {
                                    this.loadTaskAttributes(true);
                                }
                            }).catch(this.snack).finally(() => this.loadingData = false);
                    }).catch(this.snack);
            },
            loadTaskAttributes: function (setValues = false, taskId = undefined) {
                if (this.form === null && this.formRender === null) {
                    return;
                }
                Object.values(this.formRender).forEach(field => {
                    this.$set(field, 'loading', true);
                });
                const filter = {
                    [APIFilterOP.EQUALS]: {
                        'task.id': taskId ?? this.taskId
                    }
                };
                return TaskAPI.getAllAttributesAllPages({ filter: APIFilters.makeFilter(filter)} )
                    .then(response => {
                        this.existingTaskAttributes = response.data.items ?? [];
                        // do not override user entered values
                        if (setValues) {
                            this.existingTaskAttributes.forEach(attr => {
                                this.form[attr.template_id] = attr.data_type === AttributeDataTypes.BOOLEAN ? attr.value === "true" : attr.value;
                            });
                        }
                        this.$nextTick(() => {
                            this.attributesLoaded = true;
                        });
                    }).catch(this.snack)
                    .finally(() => {
                        Object.values(this.formRender).forEach(field => {
                            this.$set(field, 'loading', false);
                        });
                    });
            },
            submitCallback: function (taskId, mergedWithAnotherTask = true) {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }

                let refreshPromise = Promise.resolve();
                if (mergedWithAnotherTask && this.overridePossible) {
                    if (!this.overrideAttributes) {
                        // do not override attributes of existing task, return
                        return Promise.resolve();
                    }
                    // fetch existing attributes if task was merged with existing one during create
                    refreshPromise = this.loadTaskAttributes(false, taskId);
                }
                if (this.form === null && this.formRender === null) {
                    // nothing to submit
                    return Promise.resolve();
                }
                this.loading = true;

                return new Promise((resolve, reject) => {
                    const promises = [];
                    refreshPromise.then(() => {
                        // update existing
                        this.existingTaskAttributes.forEach(attr => {
                            const templateId = attr.template_id;
                            const updatedValue = this.form[templateId];
                            if (updatedValue === undefined
                                || updatedValue === null
                                || updatedValue === ''
                                || updatedValue === false
                                || updatedValue === 'false') {
                                promises.push(TaskAPI.deleteAttribute(taskId, attr.id));
                            } else {
                                promises.push(TaskAPI.updateAttribute(taskId, attr.id,{
                                    value: updatedValue.toString()
                                }).catch(err => {
                                    const message = err.value?.[0];
                                    if (has(this.formRender, templateId) && message) {
                                        this.$set(this.formRender[templateId], 'errors', message);
                                    }
                                    throw err;
                                }));
                            }
                        });

                        const updatedIds = this.existingTaskAttributes.map(config => config.template_id);
                        // create new
                        for (const [templateId, value] of Object.entries(this.form)) {
                            if (!value || updatedIds.includes(parseInt(templateId))) {
                                continue;
                            }
                            promises.push(TaskAPI.createAttribute(taskId, {
                                template_id: templateId,
                                value: value.toString()
                            }).catch(err => {
                                const message = err.value?.[0];
                                if (has(this.formRender, templateId) && message) {
                                    this.$set(this.formRender[templateId], 'errors', message);
                                }
                                throw err;
                            }));
                        }

                        Promise.all(promises)
                            .then(() => {
                                if (this.taskId) {
                                    this.snack('tasks.attributes.update.done');
                                    // update list of existing task attributes
                                    // we need to know whether to update or create attributes during next submit
                                    this.loadTaskAttributes()
                                        .finally(() => this.loading = false);
                                }
                                resolve();
                            })
                            .catch(err => {
                                this.snack(err);
                                this.loadTaskAttributes()
                                    .finally(() => this.loading = false);
                                reject();
                            });
                    }).catch(err => {
                        this.loading = false;
                        reject(err);
                    });
                });
            }
        }
    };
</script>

<style scoped>

</style>
