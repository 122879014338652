<template>
  <v-card>
    <v-container fluid>
      <v-flex
        xs12
      >
        <v-card-title
          v-if="!isInView"
          class="subheading"
        >
          {{ $t('buyers.types.create.label') }}
          <v-spacer />
          <v-icon
            @click="close"
          >
            $closeItem
          </v-icon>
        </v-card-title>
      </v-flex>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent
      >
        <FormFields
          ref="fields"
          :form="form"
          :render="formRender"
          lang-path="buyers.types."
        />
        <v-flex
          xs12
        >
          <v-divider
            class="my-3"
          />
          <v-btn
            :plain="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-flex>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
    import {BuyerTypeForm, BuyerTypeRender} from "@/app/buyers/types/definitions/buyerType.form";
    import {BuyerTypeAPI} from "@/api/BuyerTypeAPI";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import formRules from "@/utils/formRules";
    import {EventBus} from "@/service/EventBus";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "BuyerTypeCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            typeId: {
                type: Number,
                default: null
            },
            isInView: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new BuyerTypeForm,
            formRender: new BuyerTypeRender,
            valid: true,
            loading: false,
            formRules: formRules
        }),
        methods: {
            formFetchItem: function () {
                return BuyerTypeAPI.get(this.typeId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    BuyerTypeAPI.update(this.typeId, this.form)
                        .then(() => {
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('buyer-type-updated');
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    BuyerTypeAPI.create(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'buyers.types.create.done',
                                params: [this.form.name]
                            });
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$emit('buyer-type-updated', getIdFromLocation(response));
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-buyer-type-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>
