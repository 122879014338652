<template>
  <v-card>
    <v-dialog
      v-model="openDialog"
      max-width="500px"
      :overlay-opacity="0.9"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col
              cols="10"
              class="mt-2 mb-5"
            >
              <div v-if="panelMode === PanelMode.NEW_RACK">
                {{ $t('map.addRack') }}
              </div>
              <div v-if="panelMode === PanelMode.NEW_OBSTACLE">
                {{ $t('map.addObstacle') }}
              </div>
              <div v-if="panelMode === PanelMode.NEW_LOCATIONS_GROUP">
                {{ $t('map.newLocationGroupLocations') }}
              </div>
              <div v-if="panelMode === PanelMode.NEW_ZONE">
                {{ $t('map.newZoneType') }}
              </div>
            </v-col>
            <v-col
              style="text-align: right"
            >
              <v-btn
                icon
              >
                <v-icon
                  @click="closeDialog()"
                >
                  $close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="formValid"
            @submit.prevent
          >
            <FormFields
              v-if="[PanelMode.NEW_RACK, PanelMode.NEW_ZONE, PanelMode.NEW_OBSTACLE].includes(panelMode)"
              :form="objectNameForm"
              :render="objectNameFormRender"
              lang-path="map."
              stretch-form
            />
            <div
              v-if="panelMode === PanelMode.NEW_ZONE"
              class="mb-2 ml-4"
            >
              <v-checkbox
                v-for="[zoneType, label] of Object.entries(ZoneObjectType)"
                :key="zoneType"
                v-model="newZoneTypeInput"
                :label="$t(label)"
                :value="zoneType"
              />
            </div>
            <EditLocationsForm
              v-if="panelMode === PanelMode.NEW_LOCATIONS_GROUP"
              :locations="newLocationsInLocationsGroupInput"
              :without-commit="true"
              @addLocation="addLocationToInput"
              @removeLocation="removeLocationFromInput"
            />
            <v-btn
              :plain="!formValid"
              color="accent"
              type="submit"
              class="mt-5 ml-2 mb-2"
              @click="confirmDialog()"
            >
              {{ $t('map.create') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
    import {PanelMode} from '@/enum/stock_map';
    import {NewObjectNameForm, NewObjectNameRender} from "@/app/stocks/map/definition/objectDetails.form";
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import EditLocationsForm from "@/app/stocks/map/components/forms/EditLocationsForm.component.vue";
    import {MapMixin} from "@/app/mixins/MapMixin";
    export default {
        name: 'MapCreateObjectDialog',
        components: {EditLocationsForm, FormFields},
        mixins: [MapMixin],
        props: {
            newObjectCell: {
                type: Array,
                required: true
            },
            activate: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            objectNameForm: new NewObjectNameForm(),
            objectNameFormRender: new NewObjectNameRender(),
            formValid: false,
            newZoneTypeInput: [],
            newLocationsInLocationsGroupInput: [],
        }),
        computed: {
            openDialog: {
                get: function () {
                    return this.activate;
                },
                set: function (value) {
                    if (!value) {
                        this.closeDialog();
                    }
                }
            },
        },
        watch: {
            newLocationsInLocationsGroupInput: function (newVal){
                if (this.panelMode === PanelMode.NEW_LOCATIONS_GROUP) {
                    newVal.length === 0 ? this.formValid = false : this.formValid=true;
                }
            },
        },
        methods: {
            addLocationToInput: function (data) {
                if (this.newLocationsInLocationsGroupInput.find(loc => (loc.name === data.name && loc.stockLocationId === data.stockLocationId)) === undefined) {
                    this.newLocationsInLocationsGroupInput.push({
                        name: data.name,
                        stockLocationId: data.stockLocationId,
                        code: data.code
                    });
                }
            },
            removeLocationFromInput: function (data) {
                this.newLocationsInLocationsGroupInput = this.newLocationsInLocationsGroupInput.filter(loc => loc !== data.location);
            },
            closeDialog: function () {
                this.newZoneTypeInput = [];
                this.newLocationsInLocationsGroupInput = [];
                this.$store.commit('storageMap/setPanelMode', {
                    mode: PanelMode.STANDBY
                });
                this.$emit("closeDialog");
            },
            confirmDialog: function() {
                if (!this.formValid) {
                    return;
                }
                if (this.panelMode === PanelMode.NEW_RACK) {
                    this.$store.dispatch('storageMap/createNewRack', {
                        name: this.objectNameForm.newObjectName,
                        tl: this.newObjectCell
                    });
                }
                else if (this.panelMode === PanelMode.NEW_OBSTACLE) {
                    this.$store.commit('storageMap/createNewObstacle', {
                        tl: this.newObjectCell,
                        name: this.objectNameForm.newObjectName
                    });
                }
                else if (this.panelMode === PanelMode.NEW_ZONE) {
                    this.$store.commit('storageMap/createNewZone', {
                        name: this.objectNameForm.newObjectName,
                        type: this.newZoneTypeInput,
                        tl: this.newObjectCell
                    });
                }
                else if (this.panelMode === PanelMode.NEW_LOCATIONS_GROUP) {
                    this.$store.dispatch('storageMap/createNewLocationGroup', {
                        tl: this.newObjectCell,
                        locations: this.newLocationsInLocationsGroupInput
                    });
                }
                this.$store.commit('storageMap/setPanelMode', {
                    mode: PanelMode.STANDBY
                });
                this.closeDialog();
            },
        }
    };
</script>

<style scoped lang="sass">

.mapWindow
  height: 50vh
  overflow: auto
  background-color: var(--v-mapViewBackground-base) !important

</style>
